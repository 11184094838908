<script>
  import { _, locale } from "svelte-i18n";
  import Communications from "../../communications-service";

  export let propertyData;
  export let emailProperty;
  export let messageId;

  const getFullYear = new Date().getFullYear();

  const clickShare = () => {
    Communications.clikSocial(null, messageId, propertyData.id);
  };
</script>

<div class="container-fluid row">
  <div class="col s12  m12 l8 offset-l2  xl6 offset-xl3  feedback">
    <div class="col s12 feedback__cont__logo">
      <img class="feedback__logo" alt="logo" src={propertyData.logo} />
    </div>

    <div class="col s12 feedback__title">
      <span class="feedback__title__text {$locale}">
        {$_("We´re sorry to hear your experience was less than perfect.")}
      </span>
    </div>

    <div class="col s12 feedback__description">
      <span class="feedback__description__text {$locale}">
        {$_(
          "May we contact you to discuss your experience? If you agree, please click on the button below to share your phone number or email to contact you."
        )}</span
      >
    </div>

    <div class="col s12 feedback__actions">
      <a href={`mailto:${emailProperty}`}>
        <button class="btn button--continuebase" on:click={() => clickShare()}>
          <span class="text--button {$locale}"
            >{$_("Share phone number or email")}</span
          >
        </button>
      </a>
    </div>
  </div>
  <div class="col s12 feedback__footer">
    <div class="col s12 m5 feedback__footer__powered">
      <div class="feedback__footer__powered__move">
        <span class="feedback__footer__text"> Powered by</span>
        <img
          class="feedback__footer__logo"
          alt="footerlogo"
          src="img/logo-guesthub.png"
        />
      </div>
    </div>
    <div class="col s12 m7 feedback__footer__copyright">
      <span class="feedback__footer__text"> © {getFullYear} GuestHub </span>
      <span class="feedback__footer__text {$locale}">
        {$_("All rights reserved")}</span
      >
    </div>
  </div>
</div>

<style>
  .container-fluid {
    height: 100% !important;
  }

  .feedback {
    min-height: 95% !important;
  }

  .feedback__cont__logo {
    display: flex;
    justify-content: center;
    padding: 3.099rem 0rem 0rem 0rem !important;
  }

  .feedback__logo {
    width: 58px;
    height: 58px;
    opacity: 1;
  }

  .feedback__title {
    text-align: center;
    padding: 1.681rem 0rem 0rem 0rem !important;
  }

  .feedback__title__text {
    font-size: 2.125rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .feedback__footer {
    bottom: 0;
    z-index: -1;
  }

  .feedback__footer__logo {
    width: 6rem;
    height: 2rem;
    margin-bottom: 5px;
    opacity: 1;
  }

  .feedback__footer__powered {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem 0rem 0rem 0rem !important;
  }

  .feedback__footer__copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0rem 0rem 0rem 0rem !important;
  }

  .feedback__footer__text {
    font-size: 0.75rem;
    letter-spacing: 0px;
    color: #212121;
    margin-left: 0.3rem !important;
    opacity: 1;
  }

  .feedback__footer__powered__move {
    display: flex;
    align-items: center;
  }

  .feedback__description {
    text-align: center;
    padding: 1rem 0rem 0rem 0rem !important;
  }

  .feedback__description__text {
    font-size: 1.125rem;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .feedback__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0rem 0rem 0rem !important;
  }

  .button--continuebase {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2f4050 0% 0% no-repeat padding-box !important;
    float: right;
    cursor: pointer;
    width: auto;
    height: 38px;
    border-radius: 8px;
    opacity: 1;
  }

  .text--button {
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: initial;
    opacity: 1;
  }

  @media only screen and (min-width: 601px) {
    .feedback__footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .feedback__footer__powered__move {
      width: auto;
      float: right;
    }

    .feedback__footer__powered__move {
      float: right;
    }

    .feedback__footer__powered {
      display: block;
      width: auto !important;
      margin: 0rem !important;
    }

    .feedback__footer__text {
      float: left;
    }

    .feedback__footer__copyright {
      display: block;
      width: auto !important;
      margin: 0rem !important;
    }
  }
</style>
