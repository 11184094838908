<script>
  import { onMount, onDestroy, createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";
  import { push } from "svelte-spa-router";

  import PropertyFeatures from "../../property-features-service";

  export let id;
  export let propertyId;
  export let reservationId;
  export let logoProperty;
  export let propertyName;
  export let browserIdentify;
  export let managementColors;
  export let filterSvg;
  const dispatch = createEventDispatcher();
  let showTextArea = false;
  let showButtonSend = false;
  let commentTextarea = "";
  let feedbackResponse = "";
  let modalResponse = false;
  let modalSurvey = true;
  let modalIcons = true;
  let auth = localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth"))
    : false;
  const styleSettings = {
    "--dynamic-color": managementColors.color,
    "--filter-svg": managementColors.filter,
    "--colorLetter": managementColors.colorLetter,
    "--buttonCircle": managementColors.backgroundButtCircle,
    "--backColorAlert": managementColors.backColorAlert,
    "--backColorPromotions": managementColors.backColorPromotions,
    "--colorHoverIcon":
      managementColors.general.colorMode == "white" ? "#212121" : "#FFF",
    "--backColorIcon":
      managementColors.general.colorMode == "white" ? "#F4F4F4" : "#212121",
    "--colorButtonArea":
      managementColors.general.colorMode == "white" ? "#212121" : "#F4F4F4",
    "--colorIcon":
      managementColors.general.colorMode == "white" ? "#686C6D" : "#E3E3E3",
    "--colorTextArea":
      managementColors.general.colorMode == "white" ? "#686C6D" : "#FFF",
  };
  Object.keys(styleSettings).forEach((key) =>
    document.querySelector(":root").style.setProperty(key, styleSettings[key]),
  );
  let loading = true;
  let feedback;

  onMount(() => {
    loading = true;
    propertyId = propertyId.replace(",", "");

    PropertyFeatures.inStaFeedbackDetail({
      propertyId,
      reservationId,
      browserIdentify,
      id,
    })
      .then((feedbackDetail) => {
        feedback = feedbackDetail;
        loading = false;
      })
      .catch((err) => {
        console.error(err);
      });
  });

  onDestroy(() => {
    console.log("Component destroyed", id);
    dispatch("destroy");
  });

  const onKeyUp = (event) => {
    if (comment.toString().trim().length > 0) {
      document.querySelector("textarea").style.height = "0";
      document.querySelector("textarea").style.height =
        document.querySelector("textarea").scrollHeight + "px";
      if (event.keyCode == 13 && !event.shiftKey) {
        document.querySelector("textarea").style.height = "0";
        document.querySelector("textarea").style.height =
          document.querySelector("textarea").scrollHeight + "px";
        handleComment();
      }
    } else {
      document.querySelector("textarea").style.height = "0";
      document.querySelector("textarea").style.height =
        document.querySelector("textarea").scrollHeight + "px";
    }
  };

  const selectFeedback = (focusFeed) => {
    if (focusFeed == "negative") {
      showTextArea = true;
    } else {
      showTextArea = false;
      commentTextarea = "";
    }

    modalIcons = false;
    showButtonSend = true;
    feedbackResponse = focusFeed;
  };

  const closeModal = () => {
    // Add logic to close the modal
    PropertyFeatures.inStaFeedbackUpdateSkiped({
      propertyId,
      reservationId,
      browserIdentify,
      id,
    })
      .then(() => {
        dispatch("finish");
        console.log("hecho");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const finishedSurvey = () => {
    let status = feedbackResponse === "negative" ? "negative" : "positive";
    modalSurvey = false;
    modalResponse = true;
    showButtonSend = false;

    PropertyFeatures.inStaFeedbackUpdateFinished({
      propertyId,
      reservationId,
      browserIdentify,
      id,
      data: {
        statusCodename: status,
        commentSurvey: commentTextarea,
        guestName: (auth && auth.guestName) || feedback.guestName,
        roomNumber: (auth && auth.roomNumber) || feedback.roomNumber,
      },
    })
      .then(() => {
        console.log("hecho");
        dispatch("finish");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  let handleGoDetail = () => {
    push(`/properties/${propertyId}/my-experience/${feedback.requestId}`);
    closeModal();
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
{#if modalSurvey}
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div class="modal-overlay" on:click={closeModal}>
    <div
      class="modal-content"
      on:click|stopPropagation
      use:styling={{
        background: managementColors.general.backgroundColorCards,
      }}
    >
      {#if loading}
        <div class="preloader-wrapper big active">
          <div class="spinner-layer spinner-blue">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div>
            <div class="gap-patch">
              <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
              <div class="circle"></div>
            </div>
          </div>
        </div>
      {:else}
        <div class="col s12 padd__modal">
          <div class="cont__modal">
            <div class="cont__modal__puntuar">
              <div
                class={feedback.monitor == "feedback-fb" ||
                feedback.monitor == "feedback-services"
                  ? "cont__outlet"
                  : "cont__img"}
              >
                {#if feedback.monitor == "feedback-requests"}
                  <img
                    alt="img"
                    src="/img/request.svg"
                    class={managementColors.general.colorMode == "white"
                      ? "header-icon--white"
                      : "header-icon--dark"}
                  />
                {:else if feedback.monitor == "feedback-fb" || feedback.monitor == "feedback-services"}
                  <img
                    alt="img"
                    src={feedback.outletPhoto}
                    class="img__outlet"
                  />
                  {:else if feedback.monitor == "feedback-check-in" || feedback.monitor == "feedback-check-out"}
                  <img
                    alt="img"
                    src={logoProperty}
                    class="img__outlet"
                  />
                {/if}
              </div>
              <div class="content__information">

                <div class="cont__text">
                  <span
                    class="txt__title ng-star-inserted"
                    use:styling={{
                      color: managementColors.general.colorLetter,
                    }}
                  >
                    {feedback.fuq}
                  </span>
                  {#if feedback.monitor == 'feedback-check-in' || feedback.monitor == 'feedback-check-out'}
                  <span
                    class="txt__confirmation"
                    use:styling={{
                      color: managementColors.general.colorCardTxt,
                    }}
                  >
                    “{$_("Confirmation No")} {feedback.confirmation}”
                  </span>

                  {:else}
                  <span
                    class="txt__subtitle"
                    on:click={handleGoDetail}
                    use:styling={{
                      color: managementColors.general.colorCardTxt,
                    }}
                  >
                    “{feedback.categoryName
                      ? feedback.categoryName
                      : feedback.requestText}”
                  </span>
                  {/if}
                </div>
                {#if modalIcons}
                  <div class="cont__icons">
                    <div
                      class={feedbackResponse == "positive"
                        ? "icons__fov__green focus__green"
                        : "icons__fov__green"}
                      tabindex="0"
                      id="positive"
                      on:click={() => selectFeedback("positive")}
                    >
                      <i
                        id="iconcolorgreen"
                        class={feedbackResponse == "positive"
                          ? "icon__good__green material-icons icon__feedback"
                          : "material-icons icon__feedback icon__green"}
                        use:styling={{
                          color: managementColors.general.colorCardTxt,
                        }}
                      >
                        mood
                      </i>
                      <span
                        id="txticongreen"
                        class={feedbackResponse == "positive"
                          ? "txt__icons txt__green "
                          : "txt__icons"}
                      >
                        {$_("Like it")}
                      </span>
                    </div>
                    <div
                      class={feedbackResponse == "negative"
                        ? "icons__fov__red focus__red"
                        : "icons__fov__red"}
                      tabindex="-1"
                      id="negative"
                      on:click={() => selectFeedback("negative")}
                    >
                      <i
                        id="iconcolorred"
                        class={feedbackResponse == "negative"
                          ? "icon__bad__red material-icons icon__feedback"
                          : "material-icons icon__feedback icon__red"}
                        use:styling={{
                          color: managementColors.general.colorCardTxt,
                        }}
                      >
                        mood_bad
                      </i>
                      <span
                        id="txticon"
                        class={feedbackResponse == "negative"
                          ? "txt__icons txt__red "
                          : "txt__icons"}
                      >
                        {$_("I didn't like")}
                      </span>
                    </div>
                  </div>
                {:else}
                  <div class="cont__icons">
                    <div
                      class={feedbackResponse == "positive"
                        ? "icons__fov__green focus__green div__icons"
                        : "icons__fov__green div__icons"}
                      tabindex="0"
                      id="positive"
                      on:click={() => selectFeedback("positive")}
                    >
                      <i
                        id="iconcolorgreen"
                        class={feedbackResponse == "positive"
                          ? "icon__good__green material-icons icon__smalls"
                          : "material-icons icon__smalls icon__green"}
                        use:styling={{
                          color: managementColors.general.colorCardTxt,
                        }}
                      >
                        mood
                      </i>
                      <span
                        id="txticongreen"
                        class={feedbackResponse == "positive"
                          ? "txt__icons txt__green "
                          : "txt__icons"}
                      >
                        {$_("Like it")}
                      </span>
                    </div>
                    <div
                      class={feedbackResponse == "negative"
                        ? "icons__fov__red focus__red div__icons"
                        : "icons__fov__red div__icons"}
                      tabindex="-1"
                      id="negative"
                      on:click={() => selectFeedback("negative")}
                    >
                      <i
                        id="iconcolorred"
                        class={feedbackResponse == "negative"
                          ? "icon__bad__red material-icons icon__smalls"
                          : "material-icons icon__smalls icon__red"}
                        use:styling={{
                          color: managementColors.general.colorCardTxt,
                        }}
                      >
                        mood_bad
                      </i>
                      <span
                        id="txticon"
                        class={feedbackResponse == "negative"
                          ? "txt__icons txt__red "
                          : "txt__icons"}
                      >
                        {$_("I didn't like")}
                      </span>
                    </div>
                  </div>
                {/if}

                {#if showTextArea}
                  <div class="marg__top">
                    <label class="active label__input">
                      {$_("Please describe your experience")}
                    </label>
                    <textarea
                      placeholder={$_("Write your feedback here")}
                      id="comment"
                      type="text"
                      maxlength="150"
                      class="validate textarea-field"
                      on:keyup={onKeyUp}
                      bind:value={commentTextarea}
                    />
                    <span class="txt__help left">{$_("Optional")}</span>
                    <span class="txt__help right"
                      >{commentTextarea.length}/150</span
                    >
                  </div>
                {/if}
                {#if showButtonSend}
                  <div class="marg__top">
                    <button
                      class="btn btn-success buttom__footer"
                      use:styling={{
                        color: managementColors.general.colorAlternative,
                      }}
                      on:click={finishedSurvey}>{$_("Send")}</button
                    >
                  </div>
                {/if}
              </div>
            </div>
          </div>
        </div>
      {/if}
    </div>
  </div>
{/if}

{#if modalResponse}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="modal-overlay" on:click={() => (modalResponse = false)}>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
      class="modal-content"
      on:click={() => (modalResponse = false)}
      use:styling={{ background: managementColors.general.colorAlternative }}
    >
      <div class="modal__feedback">
        <div>
          {#if feedbackResponse == "positive"}
            <i class="material-icons feedback__green">mood</i>
          {:else}
            <i class="material-icons feedback__red">mood_bad</i>
          {/if}
        </div>
        <div class="txt__feedback">
          <span class="txt__title">
            {feedbackResponse == "positive"
              ? feedback.positive
              : feedback.negative}
          </span>
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .padd__modal {
    padding: 0px;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 103%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    background: white;
    padding: 10px 12px 0px 12px;
    border-radius: 20px;
    width: 296px;
    text-align: center;
  }
  .preloader-wrapper {
    margin: auto;
  }

  .cont__img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 126px;
    background: var(--dynamic-color);
    height: 126px;
    position: relative;
    top: -71px;
    border-radius: 8px;
  }

  .cont__outlet {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 126px;
    height: 126px;
    position: relative;
    top: -71px;
    border-radius: 8px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
  }

  .txt__title {
    font-size: 20px;
    font-weight: 700;
    color: var(--colorHoverIcon);
    display: block;
    word-break: break-word;
  }

  .txt__subtitle {
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Número de líneas */
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    margin-top: 5px;
  }

  .txt__confirmation{
    font-size: 14px;
    font-weight: 700;
    pointer-events: none;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Número de líneas */
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    margin-top: 5px;
  }

  .cont__icons {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 16px;
  }

  .icons__fov__green {
    padding: 0px 0px 16px 0px;
    border-radius: 20px;
    background: var(--backColorIcon);
  }

  .icons__fov__red {
    padding: 0px 0px 16px 0px;
    border-radius: 20px;
    background: var(--backColorIcon);
  }

  .icons__fov__green:hover {
    background: rgba(26, 179, 148, 0.2) !important;
  }

  .icons__fov__green:hover .icon__green {
    color: #1ab394 !important;
  }

  .icons__fov__green:hover .txt__icons {
    color: var(--colorHoverIcon) !important;
  }

  .icons__fov__green:focus {
    background: rgba(26, 179, 148, 0.2) !important;
  }
  .icons__fov__green:focus .icon__green {
    color: #1ab394 !important;
  }
  .icons__fov__green:focus .txt__icons {
    color: var(--colorHoverIcon) !important;
  }

  .focus__green {
    background: rgba(26, 179, 148, 0.2) !important;
  }

  .txt__green {
    color: var(--colorHoverIcon) !important;
  }

  .icon__good__green {
    color: #1ab394 !important;
  }

  .icons__fov__red:hover {
    background: rgba(255, 72, 85, 0.2) !important;
  }

  .icons__fov__red:hover .icon__red {
    color: #ff4855 !important;
  }

  .icons__fov__red:hover .txt__icons {
    color: var(--colorHoverIcon) !important;
  }

  .icons__fov__red:focus {
    background: rgba(255, 72, 85, 0.2) !important;
  }

  .icons__fov__red:focus .icon__red {
    color: #ff4855 !important;
  }

  .icons__fov__red:focus .txt__icons {
    color: var(--colorHoverIcon) !important;
  }

  .focus__red {
    background: rgba(255, 72, 85, 0.2) !important;
  }

  .txt__red {
    color: var(--colorHoverIcon) !important;
  }

  .icon__bad__red {
    color: #ff4855 !important;
  }

  .icon__feedback {
    color: var(--gh-color-dark-grey);
    font-size: 64px;
    padding: 16px 24px;
  }

  .div__icons {
    padding: 4px 0px;
    width: 42%;
  }

  .icon__smalls {
    color: var(--gh-color-dark-grey);
    padding: 8px 16px;
    font-size: 24px;
  }
  .txt__icons {
    display: block;
    color: var(--colorIcon);
    font-size: 12px;
    font-weight: 700;
  }
  .content__information {
    margin-top: -52px;
    text-align: center;
    padding: 0px 0px 32px 0px;
  }

  .buttom__footer {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px;
    margin-top: 10px;
    text-transform: capitalize;
    background-color: var(--dynamic-color);
  }

  .header-icon--white {
    max-height: 100%;
    max-width: 100%;
    width: 66px;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    filter: invert(100%) sepia(6%) saturate(755%) hue-rotate(169deg)
      brightness(117%) contrast(100%);
  }

  .img__outlet {
    max-height: 100%;
    max-width: 100%;
    width: 126px;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 8px;
  }

  .header-icon--dark {
    max-height: 100%;
    max-width: 100%;
    width: 66px;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    filter: invert(0%) sepia(96%) saturate(0%) hue-rotate(215deg)
      brightness(102%) contrast(102%);
  }

  textarea {
    border: none;
    border-bottom: 1px solid #212121;
    margin: 0px;
    padding: 0px;
    color: var(--colorHoverIcon);
    border-bottom: 1px solid var(--colorButtonArea);
  }

  textarea:active {
    border: none;
    border-bottom: 1px solid #212121;
    margin: 0px;
    padding: 0px;
    color: var(--colorHoverIcon);
    border-bottom: 1px solid var(--colorButtonArea) !important;
  }

  textarea:focus {
    border: none;
    border-bottom: 1px solid #212121;
    margin: 0px;
    padding: 0px;
    color: var(--colorHoverIcon);
    border-bottom: 1px solid var(--colorButtonArea) !important;
  }

  .marg__top {
    margin-top: 16px;
  }

  .label__input {
    float: left;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    color: var(--colorIcon);
  }

  .textarea-field {
    width: 100% !important;
    max-height: 116px !important;
    height: 36px;
    resize: none;
    padding: 9px 0px 5px 0px !important;
    word-break: break-word !important;
    color: var(--colorHoverIcon);
  }

  .txt__help {
    color: var(--colorIcon);
    font-size: 10px;
  }

  .modal__feedback {
    padding: 32px 12px;
    text-align: center;
  }

  .feedback__green {
    color: #1ab394;
    text-align: center;
    font-family: "Material Icons";
    font-size: 64px;
    padding: 8px;
    background: rgba(26, 179, 148, 0.2);
    border-radius: 100px;
    margin-bottom: 8px;
  }

  .feedback__red {
    color: #ff4855;
    text-align: center;
    font-family: "Material Icons";
    font-size: 64px;
    padding: 8px;
    background: rgba(255, 72, 85, 0.2);
    border-radius: 100px;
    margin-bottom: 8px;
  }
</style>
