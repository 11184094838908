<script>
  import PropertyFeatures from "../../property-features-service";
  import Communications from "../../communications-service";
  import Survey from "./survey.svelte";
  import SurveyPositive from "./feedback-positive.svelte";
  import SurveyNegative from "./feddback-negative.svelte";
  import Reloading from "../../components/navigation/reloading-component.svelte";
  import TermsAndConditions from "./terms-and-conditions.svelte";
  import { init } from "svelte-i18n";

  export let params = {};

  let state = Object.assign({ features: [], reservation: undefined }, params);
  let propertyData = {};
  let comments = "";
  let messageData = {};
  let vteExperience = 0;
  let vteRecomendation = 0;
  let messageId = state.messageId;
  let viewToShow = "reloading";
  let arraySocialMeidia = [];
  let managementColors = "";

  window.location && window.location.toString();
  window.location.toString().includes("?lang=")
    ? init({
        fallbackLocale: "en",
        initialLocale: window.location.toString().split("?lang=")[1],
      })
    : undefined;

  let initComponent = () => {
    Promise.all([
      Communications.getByReviewId(state.messageId, state.propertyId),
      Communications.getSocialMedia(state.propertyId),
      PropertyFeatures.proppertyInformation({ propertyId: state.propertyId }),
    ]).then(([message, socialMedia, propertyInformation]) => {
      messageData = message;
      vteExperience = message.type.attributes.vteExperience;
      vteRecomendation = message.type.attributes.vteRecomendation;
      comments = message.type.attributes.comments;
      arraySocialMeidia = socialMedia;
      propertyData = propertyInformation;

      validationToViewShow(
        messageData.status,
        messageData.type.attributes.vteExperience
      );
    });
  };

  let validationToViewShow = (status, experience) => {
    if (status == "created") {
      if (state.propertyId === "132") {
        const terms = localStorage.getItem("hasAcceptedTerms");
        if (terms !== "true") {
          viewToShow = "terms-and-conditions";
        } else {
          viewToShow = "survey";
        }
      } else {
        viewToShow = "survey";
      }
    } else if (status == "FINISHED" && experience > 3) {
      viewToShow = "survey-positive";
    } else if (status == "FINISHED" && experience <= 3) {
      viewToShow = "survey-negative";
    } else if (status == "terms-and-conditions") {
      viewToShow = "terms-and-conditions";
    } else {
      viewToShow = "reloading";
    }
  };

  let handleSendSurvey = (statesExperience, statesRecomendate, comments) => {
    Communications.updateByReviewId(
      Object.assign({}, messageData, {
        type: Object.assign({}, messageData.type, {
          attributes: Object.assign({}, messageData.type.attributes, {
            vteExperience: statesExperience.filter((r) => r).length,
            vteRecomendation: statesRecomendate.filter((r) => r).length,
            comments: comments,
          }),
        }),
      }),
      messageId,
      state.propertyId
    )
      .then(() => {
        initComponent();
      })
      .catch((err) => {});
  };

  initComponent();

  const onTermsAndConditionsSelected = (hasAcceptedTerms) =>
    (hasAcceptedTerms &&
      localStorage.setItem("hasAcceptedTerms", hasAcceptedTerms)) ||
    validationToViewShow(
      messageData.status,
      messageData.type.attributes.vteExperience
    );
</script>

<div class="container-fluid row">
  {#if viewToShow == "survey"}
    <Survey
      bind:propertyData
      bind:nameGuest={messageData.type.attributes.reservation.guest.name}
      bind:sendSurvey={handleSendSurvey}
    />
  {:else if viewToShow == "survey-positive"}
    <SurveyPositive
      bind:propertyData
      bind:experienceLength={vteExperience}
      bind:messageId={state.messageId}
      bind:recomendedLength={vteRecomendation}
      bind:comments
      bind:arraySocialMeidia
    />
  {:else if viewToShow == "survey-negative"}
    <SurveyNegative
      bind:propertyData
     emailProperty={propertyData && propertyData.contactGuestInformation.find(p => p.contenType == 'mail')
      ? propertyData.contactGuestInformation.filter(p => p.contenType == 'mail').map(e => e.link).join(', ')
      : ""}
      bind:messageId={state.messageId}
    />
  {:else if viewToShow == "terms-and-conditions"}
    <TermsAndConditions
      bind:propertyData
      onAccepted={onTermsAndConditionsSelected}
    />
  {:else}
    <Reloading bind:managementColors />
  {/if}
</div>

<style>
  .container-fluid {
    height: 100% !important;
  }
</style>
